import '../styles.scss';
import React, { useState, useRef } from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';

const SolicitanteRegionalRankDinamico = ({ data }) => {
  const titulo = 'RANK SOLICITANTES POR REGIONAL';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const graficoRef = useRef(null);
  const graficoSolicitantesRef = useRef(null);

  const dataModel = {
    titulo: '',
    series: [{ data: [] }],
    categorias: [],
    colors: [],
  };

  const [modelSolicitante, setModelSolicitante] = useState(dataModel);

  const colors = ['#7daee3', '#30CA60', '#0af7ff', '#fa78e6', '#dedede'];

  const regionais = data.map((item) => {
    return item.nome;
  });

  const regionaisTotal = data.map((item) => {
    return item.totalSolicitacao;
  });

  const updateRegional = (index, selectedDataPoints) => {
    const grafico = graficoRef.current;
    const graficoSolicitantes = graficoSolicitantesRef.current;

    if (selectedDataPoints[0].length === 0) {
      grafico.classList.remove('regional-ativo');
      graficoSolicitantes.classList.remove('solicitante-ativo');
      return;
    }

    if (!grafico.classList.contains('regional-ativo')) {
      grafico.classList.add('regional-ativo');
    }
    if (!graficoSolicitantes.classList.contains('solicitante-ativo')) {
      graficoSolicitantes.classList.add('solicitante-ativo');
    }

    const regional = data[index];

    const solicitantes = regional.solicitante.map((item) => {
      return item.nome;
    });

    const solicitantesTotal = regional.solicitante.map((item) => {
      return item.totalSolicitacao;
    });

    setModelSolicitante({
      titulo: regional.nome,
      series: [{ data: solicitantesTotal }],
      categorias: solicitantes,
      colors: [colors[index % colors.length]],
    });
  };

  const series = [
    {
      data: regionaisTotal,
    },
  ];

  const options = {
    subtitle: {
      text: '(Clique na barra para ver o ranking completo)',
      offsetY: 15,
      offsetX: 15,
      floating: true,
      style: {
        fontSize: '1rem',
        fontWeight: 'bolder',
        fontFamily: 'Branding',
        color: '#263238',
      },
    },
    legend: {
      show: false,
    },
    colors: [...colors],
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        barHeight: '75%',
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      id: 'regionais',
      sparkline: {
        enabled: false,
      },
      parentHeightOffset: 0,
      width: '100%',
      events: {
        dataPointSelection: (event, chartContext, config) => {
          updateRegional(config.dataPointIndex, config.selectedDataPoints);
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#000000'],
      },
      formatter: (value, { dataPointIndex }) => {
        return regionais[dataPointIndex];
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
        color: '#fff',
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Regional: <span id="data">${regionais[dataPointIndex]}</span>
            </span>
            <span>
              Total Solicitações Criadas: <span id="data">${parseInt(
                regionaisTotal[dataPointIndex],
                10
              ).toLocaleString('pt-BR')}</span>
            </span>
          </div>
          `;
      },
    },
  };

  const optionsSolicitantes = {
    title: {
      text: `Ranking de Solicitantes - ${modelSolicitante.titulo}`,
      offsetY: 15,
      offsetX: 15,
      floating: true,
      style: {
        fontSize: '1rem',
        fontWeight: 'bolder',
        fontFamily: 'Branding',
        color: '#263238',
      },
    },
    legend: {
      show: false,
    },
    colors: [...modelSolicitante.colors],
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        barHeight: '75%',
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      id: 'regionais-solicitantes',
      sparkline: {
        enabled: false,
      },
      parentHeightOffset: 0,
      width: '100%',
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#000000'],
      },
      formatter: (value, { dataPointIndex }) => {
        return `${modelSolicitante.categorias[dataPointIndex]} - ${value}`;
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
        color: '#fff',
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Regional: <span id="data">${regionais[dataPointIndex]}</span>
            </span>
            <span>
              Total Solicitações Criadas: <span id="data">${parseInt(
                modelSolicitante.series[0].data[dataPointIndex],
                10
              ).toLocaleString('pt-BR')}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div style={{ height: '300px', position: 'relative' }}>
        <div id="grafico-regional" ref={graficoRef}>
          <Chart
            options={options}
            series={series}
            height="100%"
            width="100%"
            type="bar"
          />
        </div>
        <div id="grafico-regional-solicitante" ref={graficoSolicitantesRef}>
          <Chart
            options={optionsSolicitantes}
            series={modelSolicitante.series}
            height="100%"
            width="100%"
            type="bar"
          />
        </div>
      </div>
    </div>
  );
};

export default SolicitanteRegionalRankDinamico;
