/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { compareString } from '../../../../_constants';

const BuscaProdutos = (props) => {
  let entities = props.entities;
  let solicitacaoProdutos = props.solicitacaoProdutos;
  let adicionarProduto = props.adicionarProduto;

  const [produtos, setProdutos] = useState(entities.produtos || []);
  const [categoriaId1, setCategoriaId1] = useState(0);
  const [categoriaId2, setCategoriaId2] = useState(0);
  const [categoriaId3, setCategoriaId3] = useState(0);

  const [selectListCategoria2, setSelectListCategoria2] = useState([]);
  const [selectListCategoria3, setSelectListCategoria3] = useState([]);
  const [codigoNome, setCodigoNome] = useState('');

  let categoriasNivel1 =
    (entities.produtoCategorias || []).filter(
      (categoria) => !categoria.produtoCategoriaSuperiorId
    ) || [];

  let categoriasNivel2 =
    (entities.produtoCategorias || []).filter(
      (categoria) =>
        categoriasNivel1.findIndex(
          (categoria1) =>
            categoria1.produtoCategoriaId ===
            categoria.produtoCategoriaSuperiorId
        ) !== -1
    ) || [];

  let categoriasNivel3 =
    (entities.produtoCategorias || []).filter(
      (categoria) =>
        categoriasNivel2.findIndex(
          (categoria2) =>
            categoria2.produtoCategoriaId ===
            categoria.produtoCategoriaSuperiorId
        ) !== -1
    ) || [];

  let selectListCategoria1 = categoriasNivel1.map((categoria) => {
    return {
      value: categoria.produtoCategoriaId,
      label: categoria.nome,
    };
  });

  useEffect(() => {
    setSelectListCategoria2(
      categoriasNivel2
        .filter(
          (categoria) => categoria.produtoCategoriaSuperiorId == categoriaId1
        )
        .map((categoria) => {
          return {
            value: categoria.produtoCategoriaId,
            label: categoria.nome,
          };
        })
    );
  }, [categoriaId1]);

  useEffect(() => {
    setSelectListCategoria3(
      categoriasNivel3
        .filter(
          (categoria) => categoria.produtoCategoriaSuperiorId == categoriaId2
        )
        .map((categoria) => {
          return {
            value: categoria.produtoCategoriaId,
            label: categoria.nome,
          };
        })
    );
  }, [categoriaId2]);

  useEffect(() => {
    searchProdutos();
  }, [categoriaId1, categoriaId2, categoriaId3, codigoNome]);

  const searchProdutos = () => {
    let produtos = entities.produtos || [];

    if (solicitacaoProdutos.length > 0)
      produtos = produtos.filter(
        (produto) =>
          solicitacaoProdutos.findIndex(
            (sp) => sp.produtoId === produto.produtoId
          ) === -1
      );

    if (codigoNome.length > 0)
      produtos = produtos.filter(
        (produto) =>
          compareString(produto.nome, codigoNome) ||
          compareString(produto.ean, codigoNome)
      );

    let categoriaIds = [];
    if (categoriaId3 > 0)
      categoriaIds =
        (
          categoriasNivel3.find(
            (categoria) => categoria.produtoCategoriaId === categoriaId3
          ) || {}
        ).hierarquiaAbaixoIds || [];
    else if (categoriaId2 > 0)
      categoriaIds =
        (
          categoriasNivel2.find(
            (categoria) => categoria.produtoCategoriaId === categoriaId2
          ) || {}
        ).hierarquiaAbaixoIds || [];
    else if (categoriaId1 > 0)
      categoriaIds =
        (
          categoriasNivel1.find(
            (categoria) => categoria.produtoCategoriaId === categoriaId1
          ) || {}
        ).hierarquiaAbaixoIds || [];

    if (categoriaIds.length > 0)
      produtos = produtos.filter(
        (produto) => categoriaIds.indexOf(produto.produtoCategoriaId) !== -1
      );

    setProdutos([...produtos]);
  };

  const renderFiltro = () => {
    return (
      <div className="row mt-3 mb-3">
        <div className="col-lg-6">
          <div className="mb-4">
            <label>Marca</label>
            <Select
              options={selectListCategoria1}
              isClearable={true}
              onChange={(item) => setCategoriaId1(item?.value)}
              value={selectListCategoria1.find(
                (item) => item.value === categoriaId1
              )}
            />
          </div>

          {selectListCategoria2.length ? (
            <div className="mb-4">
              <label>Categoria</label>
              <Select
                options={selectListCategoria2}
                isClearable={true}
                onChange={(item) => setCategoriaId2(item?.value)}
                value={selectListCategoria2.find(
                  (item) => item.value === categoriaId2
                )}
              />
            </div>
          ) : null}

          {selectListCategoria3.length ? (
            <div className="mb-4">
              <label>Grupo</label>
              <Select
                options={selectListCategoria3}
                isClearable={true}
                onChange={(item) => setCategoriaId3(item?.value)}
                value={selectListCategoria3.find(
                  (item) => item.value === categoriaId3
                )}
              />
            </div>
          ) : null}

          <div className="mb-4">
            <label>EAN ou Nome do produto</label>
            <input
              type="text"
              className="form-control"
              value={codigoNome || ''}
              onChange={(event) => setCodigoNome(event.target.value)}
            />
          </div>
        </div>
      </div>
    );

    return null;
  };

  const renderProdutos = () => {
    if (produtos.length === 0) {
      return <p>Nenhum produto encontrado.</p>;
    }

    return (
      <div className="table-search-produtos-wrapper">
        <table className="table table-striped table-search-produtos">
          <thead>
            <tr>
              <th>EAN</th>
              <th>Nome</th>
              <th>Marca</th>
              <th>Categoria</th>
              <th>Grupo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {produtos.map((produto, index) => {
              return (
                <tr key={index}>
                  <td>{produto.ean}</td>
                  <td>{produto.nome}</td>
                  <td>{produto.marca}</td>
                  <td>{produto.categoria}</td>
                  <td>{produto.grupo}</td>
                  <td>
                    <button
                      className="btn btn-primary btn-navigation btn-navigation-xs"
                      onClick={() => adicionarProduto(produto)}
                    >
                      <span>+ Adicionar</span>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      {renderFiltro()}

      {renderProdutos()}
    </div>
  );
};

export default BuscaProdutos;
