import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';

const RedeArte = (props) => {
  const { data } = props;
  const titulo = 'SOLICITAÇÕES POR REDE';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const itens = data.map((item) => {
    return item.nome;
  });

  const itensQtd = data.map((item) => {
    return item.total;
  });

  const itensPorcentagem = data.map((item) => {
    return item.porcentagem;
  });

  const maxValue = Math.max(...itensQtd);

  const getBarColor = (value) => {
    const porcentagem = (value / maxValue) * 100;
    return `hsl(${porcentagem}, 30%, 46%)`;
  };

  const series = [
    {
      data: itensQtd,
    },
  ];

  const options = {
    // colors: [colors.secondary],
    colors: [
      ({ value }) => {
        return getBarColor(value);
      },
    ],
    plotOptions: {
      bar: {
        barHeight: '80%',
        horizontal: true,
        dataLabels: {
          position: 'top',
          orientation: 'horizontal',
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: itens,
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      max: maxValue * 1.35,
      labels: {
        align: 'left',
        minWidth: 0,
        maxWidth: 100,
        offsetX: -8,
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    grid: {
      padding: {
        left: 20,
      },
      row: {
        colors: ['#dcdcdc', 'transparent'],
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      parentHeightOffset: 0,
      width: '100%',
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'start',
      offsetX: 5,
      offsetY: 6,
      formatter: (value, { dataPointIndex }) => {
        return `${value.toLocaleString('pt-BR')} (${itensPorcentagem[
          dataPointIndex
        ].toLocaleString('pt-BR')}%)`;
      },
      style: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              <span id="data">${itens[dataPointIndex]}</span>
            </span>
            <span>
              Total de Solicitações: <span id="data">${series[seriesIndex][dataPointIndex]}</span>
            </span>
            <span>
              %: <span id="data">${itensPorcentagem[dataPointIndex]}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="scrollable">
        <Chart
          options={options}
          series={series}
          height={itens.length * 17 + 100}
          type="bar"
        />
      </div>
    </div>
  );
};

export default RedeArte;
