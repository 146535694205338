import React from 'react';

import {
  CanalSolicitacao,
  CidadeSolicitacao,
  EvolucaoSolicitacao,
  MaterialSolicitacao,
  RedeSolicitacao,
  RegionalSolicitacao,
  SolicitanteSolicitacao,
  UFSolicitacao,
} from '../Graficos/produtividade';

const TabProdutividade = React.memo((props) => {
  const { data } = props;
  return (
    <div className="container-tab">
      <div className="row-graficos">
        <EvolucaoSolicitacao data={data.solicEvolucao} />
      </div>
      <div className="row-graficos">
        <UFSolicitacao data={data.solicUF} />
        <CidadeSolicitacao data={data.solicCidade} />
        <RedeSolicitacao data={data.solicRede} />
        <SolicitanteSolicitacao data={data.solicSolicitante} />
      </div>
      <div className="row-graficos">
        <MaterialSolicitacao data={data.solicMaterial} />
        <RegionalSolicitacao data={data.solicRegional} />
        <CanalSolicitacao data={data.solicCanal} />
      </div>
    </div>
  );
});

export default TabProdutividade;
