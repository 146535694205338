import React from 'react';
import {
  // BrasilVerba,
  CanalInvestimentoTabela,
  MaterialInvestimento,
  MaterialInvestimentoTabela,
  MesInvestimento,
  ProdutoInvestimentoTabela,
  RegionalCustoMedio,
  RegionalInvestimento,
  RegionalInvestimentoTabela,
  RegionalVerba,
  SolicitanteInvestimentoTabela,
  // VerbaDisponivel,
} from '../Graficos/custo';

const TabCusto = React.memo(({ data }) => {
  return (
    <div className="container-tab">
      <div className="row-graficos">
        {/* <BrasilVerba data={data.verbaBrasil} /> */}
        <RegionalVerba data={data.verbaRegional} />
      </div>
      {/* <div className="row-graficos">
        <VerbaDisponivel data={data.verbaDisponivel} />
      </div> */}
      <div className="row-graficos">
        <MesInvestimento data={data.investimentoTotalMensal} />
      </div>
      <div className="row-graficos">
        <RegionalInvestimento data={data.investimentoTotalRegional} />
      </div>
      <div className="row-graficos">
        <RegionalCustoMedio data={data.custoMedioSolicitacaoMensal} />
      </div>
      <div className="row-graficos">
        <RegionalInvestimentoTabela data={data.investimentoRegional} />
      </div>
      <div className="row-graficos">
        <MaterialInvestimento
          data={data.investimentoMaterialMensal}
          dataLabels={data.labelsMaterial}
        />
      </div>
      <div className="row-graficos">
        <CanalInvestimentoTabela data={data.investimentoCanal} />
      </div>
      <div className="row-graficos">
        <MaterialInvestimentoTabela data={data.investimentoMaterial} />
      </div>
      <div className="row-graficos">
        <ProdutoInvestimentoTabela data={data.investimentoProduto} />
      </div>
      <div className="row-graficos">
        <SolicitanteInvestimentoTabela data={data.investimentoSolicitante} />
      </div>
    </div>
  );
});

export default TabCusto;
