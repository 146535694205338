import React from 'react';
import './styles.scss';

const SemDados = (props) => {
  const { titulo } = props;
  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo || 'Sem dados para exibir'}</span>
      </div>
      <div className="grafico-sem-dados">
        <span>Sem dados para exibir</span>
      </div>
    </div>
  );
};

export default SemDados;
