/* eslint-disable */
import api from 'api';
import MockRelatorios from 'helpers/MockRelatorios';

const qs = require('qs');

const PATH = '/RelatorioGrafico';

const GET_RELATORIO_GRAFICO = async (filtros) => {
  console.log(filtros);
  const { data } = await api.get(`${PATH}/get-relatorio-grafico`, {
    params: filtros,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  console.log(data);
  return data;
};

export default GET_RELATORIO_GRAFICO;
