import React from 'react';
// import Chart from 'react-apexcharts';
import './styles.scss';
import { parseIntLocaleString } from 'util/numberUtil';
// import colors from 'themes/_variables.module.scss';

const CardsCusto = React.memo(
  ({ data }) => {
    if (!data) return null;

    // const series = [
    //   Math.round(data?.verbaUtilizada),
    //   Math.round(data?.verbaDisponivel - data?.verbaUtilizada),
    // ];
    // const options = {
    //   colors: [colors.primaryLight, colors.secondary],
    //   chart: {
    //     type: 'donut',
    //   },
    //   labels: ['Verba Utilizada', 'Verba Disponível'],
    //   dataLabels: {
    //     enabled: true,
    //   },
    //   legend: {
    //     show: false,
    //   },
    //   stroke: {
    //     width: 0,
    //   },
    //   tooltip: {
    //     enabled: false,
    //   },
    //   plotOptions: {
    //     pie: {
    //       dataLabels: {
    //         offset: 0,
    //       },
    //     },
    //   },
    // };

    return (
      <div className="card-wrapper">
        <div className="card">
          <p className="card-title">Total de solicitações</p>
          <p className="card-data">{parseIntLocaleString(data?.solicitacao)}</p>
        </div>
        {/* <div className="card">
          <p className="card-title">Verba Disponibilizada</p>
          <p className="card-data">
            {`R$ ${parseIntLocaleString(data?.verbaDisponivel / 1000)}K`}
          </p>
        </div> */}
        <div className="card">
          <p className="card-title">Verba Utilizada</p>
          <p className="card-data">
            {`R$ ${parseIntLocaleString(data?.verbaUtilizada / 1000)}K`}
          </p>
        </div>
        {/* <div className="card">
          <p className="card-title">Saldo de Verba</p>
          <p className="card-data">
            {`R$ ${parseIntLocaleString(
              (data?.verbaDisponivel - data?.verbaUtilizada) / 1000
            )}K`}
          </p>
        </div> */}
        {/* <div className="card">
          <p className="card-title">% Verba Utilizada</p>
          <div
            style={{
              // height: '70px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Chart options={options} series={series} type="pie" height="80" />
          </div>
        </div> */}
        <div className="card">
          <p className="card-title">Qtd Impressos</p>
          <p className="card-data">
            {parseIntLocaleString(data?.qtdeImpresso)}
          </p>
        </div>
        <div className="card">
          <p className="card-title">Custo Médio por Impressão</p>
          <p className="card-data">
            {`R$ ${data?.custoMedioImpressao
              .toLocaleString('pt-BR')
              .replace('.', ',')}`}
          </p>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default CardsCusto;
