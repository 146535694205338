/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Tab, Badge, Modal, Button, Row, Col } from 'react-bootstrap';
import './styles.scss';
import api from 'api';
import { GET_LIST_PENDENCIA_USUARIO_ATUAL } from 'services/Pendencia';
import { CANCELAR_SOLICITACAO } from 'services/SolicitacaoFluxo';
import { Context } from 'context/AuthContext';
import SingleSelect from 'components/_base/SelectSingle';

const ContentPendencias = () => {
  const [searched, setSearched] = useState([]);
  const [pendencias, setPendencias] = useState([]);
  const [solicitacaoId, setSolicitacaoId] = useState(0);
  const [materialComunicacaoId, setMaterialComunicacaoId] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const { getMaterialComunicacaoSelectList, showLoader } = useContext(Context);
  const [materialList, setMaterialList] = useState([]);
  const [modal, setModal] = useState({
    ativo: false,
    pendenciaId: null,
  });

  useEffect(() => {
    (async () => {
      setMaterialList(await getMaterialComunicacaoSelectList());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      showLoader(true);

      const data = await GET_LIST_PENDENCIA_USUARIO_ATUAL();
      setPendencias(data.value);
      setSearched(data.value);
      showLoader(false);
    })();
  }, []);

  function searchByMaterialComunicacao(value) {
    let materialComunicacaoId = value;

    setMaterialComunicacaoId(materialComunicacaoId);

    searchPendencia(solicitacaoId, materialComunicacaoId);
  }

  function searchBySolicitacaoId(event) {
    let solicitacaoId = event.target.value;

    setSolicitacaoId(solicitacaoId);

    searchPendencia(solicitacaoId, materialComunicacaoId);
  }

  function searchPendencia(solicitacaoId, materialComunicacaoId) {
    let filtered = pendencias.map((lockedTipo) => {
      let tipo = { ...lockedTipo };

      if (solicitacaoId > 0)
        tipo.pendencias = tipo.pendencias.filter(
          (pendencia) => pendencia.solicitacaoId == solicitacaoId
        );

      if (materialComunicacaoId > 0)
        tipo.pendencias = tipo.pendencias.filter(
          (pendencia) =>
            pendencia.materialComunicacaoId == materialComunicacaoId
        );

      return tipo;
    });

    filtered = filtered.filter((tipo) => tipo.pendencias.length > 0);

    setSearched(filtered);
    setActiveTab(0);
  }

  async function cancelarPendencias(solicitacaoId) {
    showLoader(true);
    setModal({ ativo: false, pendenciaId: null });
    await CANCELAR_SOLICITACAO(solicitacaoId);
    const data = await GET_LIST_PENDENCIA_USUARIO_ATUAL();
    setPendencias(data.value);
    setSearched(data.value);
    setActiveTab(0);
    showLoader(false);
  }

  function ModalCancelar() {
    return (
      <Modal
        show={modal.ativo}
        onHide={() => setModal({ ativo: !modal.ativo, pendenciaId: null })}
        className="modal-discard-draft"
      >
        <Modal.Body>
          <h3>
            Tem certeza que deseja descartar esse pedido n° {modal.pendenciaId}{' '}
            ?
          </h3>
          <p>
            Ao clicar em descartar, seu pedido será apagado e você não poderá
            continuá-lo ou acessar as informações. Deseja continuar?
          </p>
          <Row className="buttons">
            <Col className="text-center">
              <Button
                variant="secondary"
                onClick={() =>
                  setModal({ ativo: !modal.ativo, pendenciaId: null })
                }
                className="button"
              >
                CANCELAR
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                variant="danger"
                onClick={() => cancelarPendencias(modal.pendenciaId)}
                className="button"
              >
                DESCARTAR
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  function renderTabs() {
    if (!searched || searched.length === 0) return null;

    return (
      <>
        <hr />

        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
          {searched.map((tipo, index1) => {
            return (
              <Tab
                key={index1}
                eventKey={index1}
                title={
                  <React.Fragment>
                    {tipo.descricao}{' '}
                    <Badge variant="danger">({tipo.pendencias.length})</Badge>
                  </React.Fragment>
                }
              >
                <div className="table-query ">
                  <div className="table-responsive">
                    <table className="table table-stripped">
                      <thead>
                        <tr>
                          <th>Nro</th>
                          <th>Regional</th>
                          <th>Material</th>
                          <th>Cliente</th>
                          <th>Data Limite</th>
                          <th>Data Criação</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {tipo.pendencias.map((pendenciaItem, index2) => {
                          return (
                            <tr
                              key={index2}
                              className={pendenciaItem.alert ? 'alerta' : ''}
                            >
                              <td className="monospace">
                                {pendenciaItem.solicitacaoId}
                              </td>
                              <td>{pendenciaItem.regional}</td>
                              <td>{pendenciaItem.materialComunicacao}</td>
                              <td>{pendenciaItem.razaoSocial}</td>
                              <td className="monospace">
                                {pendenciaItem.dataLimite}
                              </td>
                              <td className="monospace">
                                {pendenciaItem.dataCriacaoStr}
                              </td>
                              <td className="text-center">
                                <Button
                                  className="table-button"
                                  href={
                                    'solicitacao-fluxo/status?solicitacaoId=' +
                                    pendenciaItem.solicitacaoId
                                  }
                                >
                                  Visualizar
                                </Button>
                                {pendenciaItem.solicitacaoReprovada && (
                                  <Button
                                    className="ml-2 btn-danger table-button"
                                    onClick={() =>
                                      setModal({
                                        ativo: true,
                                        pendenciaId:
                                          pendenciaItem.solicitacaoId,
                                      })
                                    }
                                  >
                                    Cancelar
                                  </Button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Tab>
            );
          })}
        </Tabs>
      </>
    );
  }

  return (
    <div className="wrapper-content-pendencias">
      <div className="content-pendencias">
        <div className="row">
          <form className="col-12 pesquisa">
            <div className="row tipo">
              <label className="col-lg-3 col-form-label  " htmlFor="">
                Número da solicitação
              </label>
              <div className="col-lg-9 ">
                <input
                  type="text"
                  className="form-control "
                  onChange={searchBySolicitacaoId}
                />
              </div>
            </div>

            <div className="row tipo">
              <label className="col-lg-3 col-form-label numero" htmlFor="">
                Material de Comunicação
              </label>
              <div className="col-lg-9 ">
                <SingleSelect
                  isClearable={true}
                  className="form-control "
                  onChange={searchByMaterialComunicacao}
                  options={materialList}
                  value={materialComunicacaoId}
                />
              </div>
            </div>
          </form>
        </div>

        {renderTabs()}
      </div>
      {ModalCancelar()}
    </div>
  );
};

export default ContentPendencias;
