import React from 'react';
import './styles.scss';
import { parseIntLocaleString } from 'util/numberUtil';

const CardsParticipacao = React.memo(
  ({ data }) => {
    if (!data) return null;
    return (
      <div className="card-wrapper">
        <div className="card">
          <p className="card-title">Total de solicitações</p>
          <p className="card-data">{parseIntLocaleString(data?.solicitacao)}</p>
        </div>
        <div className="card">
          <p className="card-title">Solicitações aprovadas</p>
          <p className="card-data">
            {parseIntLocaleString(data?.solicitacaoAprovada)}
          </p>
        </div>
        <div className="card">
          <p className="card-title">Total Arte Aprovada</p>
          <p className="card-data">
            {parseIntLocaleString(data?.totalArteAprovada)}
          </p>
        </div>
        <div className="card">
          <p className="card-title">Total de Encartes Aprovados</p>
          <p className="card-data">
            {parseIntLocaleString(data?.totalEncarteAprovado)}
          </p>
        </div>
        <div className="card">
          <p className="card-title">Qtd Impressos</p>
          <p className="card-data">
            {parseIntLocaleString(data?.qtdeImpresso)}
          </p>
        </div>
        <div className="card">
          <p className="card-title">Média de Impressos aprovados por encarte</p>
          <p className="card-data">
            {parseIntLocaleString(data?.mediaImpressosEncarte)}
          </p>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default CardsParticipacao;
