const lineBreaker = (label, length) => {
  const maxLength = length;
  const lines = [];
  label.split(' ').forEach((word) => {
    if (lines.length === 0) {
      lines.push(word);
    } else {
      const i = lines.length - 1;
      const line = lines[i];

      if (line.length + 1 + word.length <= maxLength) {
        lines[i] = `${line} ${word}`;
      } else {
        lines.push(word);
      }
    }
  });
  return lines;
};

export default lineBreaker;
