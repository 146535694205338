import api from 'api';

const PATH = '/BancoArte';
const qs = require('qs');

const GET_LIST_BANCO_ARTE = async (filtro) => {
  const { data } = await api.get(
    `${PATH}/get-list-paged-banco-arte-por-filtro`,
    {
      params: { ...filtro, SortDirection: 'DESC' },
      paramsSerializer: (filtroSerializer) => {
        return qs.stringify(filtroSerializer);
      },
    }
  );
  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { GET_LIST_BANCO_ARTE };
