import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import './styles.scss';

const DropDownFiltro = (props) => {
  // selecao é um objeto que contem os valores selecionados de todos dropdown
  // os filtros contém apenas os valores deste dropdown
  const { setFetchDataTrigger, nome, filtro, filtroDisp, selecao, setSelecao } =
    props;

  if (filtro === null || filtroDisp === null) {
    return null;
  }

  const [tudo, setTudo] = useState(false);
  const [show, setShow] = useState(false);
  const [tmpSelecao, setTmpSelecao] = useState(selecao[nome]);

  // atualiza tmpSelecao quando selecao é atualizada
  useEffect(() => {
    setTmpSelecao(selecao[nome]);
  }, [selecao]);

  // atualiza tudo quando tmpSelecao é atualizada
  useEffect(() => {
    setTudo(tmpSelecao?.length === filtroDisp?.length);
  }, [tmpSelecao]);

  const toggleShow = () => setShow((prev) => setShow(!prev));

  // faz uma copia da selecao, e altera o valor somente do dropdown atual
  const aplicarSelecao = () => {
    const tmp = { ...selecao };
    tmp[nome] = [...tmpSelecao];
    setSelecao(tmp);
    // atualiza o trigger para refazer a requisição
    setFetchDataTrigger((prev) => !prev);
    toggleShow();
  };

  // cancela a selecao, e volta para o valor anterior
  const cancelarSelecao = () => {
    setTmpSelecao(selecao[nome]);
    toggleShow();
  };

  // muda todos os valores do dropdown para o valor passado
  const mudarTodos = (value) => {
    if (value) {
      const tmp = [...filtroDisp];
      setTmpSelecao(tmp);
    } else {
      const tmp = [];
      setTmpSelecao(tmp);
    }
    setTudo(value);
  };

  const handleChange = (id, value) => {
    const tmp = [...tmpSelecao];
    if (value) {
      tmp.push(id === '' ? null : id); // verificando se id é vazio para substituir por null
    } else {
      tmp.splice(tmp.indexOf(id === '' ? null : id), 1); // verificando se id é vazio para substituir por null
    }
    setTmpSelecao(tmp);
  };

  return (
    <Dropdown drop="down" show={show} onToggle={(isOpen) => setShow(isOpen)}>
      <Dropdown.Toggle split>
        {selecao[nome]?.length === filtroDisp?.length // ?. para evitar erro de null
          ? '(Tudo)'
          : '(Valores múltiplos)'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Form.Check
          key="tudo"
          type="checkbox"
          label="(tudo)"
          id="tudo"
          checked={tudo}
          onChange={(e) => {
            mudarTodos(e.target.checked);
          }}
        />
        <Dropdown.Divider />
        <div className="dropdonwn-scrollable">
          {filtro?.map((opcao) => {
            return (
              <Form.Check
                key={opcao}
                type="checkbox"
                label={opcao ?? 'Nulo'}
                id={opcao}
                checked={tmpSelecao?.includes(opcao)} // ?. para evitar erro de null
                disabled={!filtroDisp?.includes(opcao)} // ?. para evitar erro de null
                onChange={(e) => {
                  handleChange(e.target.id, e.target.checked);
                }}
              />
            );
          })}
        </div>
        <Dropdown.Divider />
        <div className="btn-container">
          <button type="button" onClick={() => cancelarSelecao()}>
            Cancelar
          </button>
          <button
            className="btn-aplicar"
            type="button"
            onClick={() => aplicarSelecao()}
          >
            Aplicar
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDownFiltro;
