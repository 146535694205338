import '../styles.scss';
import React from 'react';
import SemDados from '../SemDados';

const EvolucaoSolicitacao = (props) => {
  const { data } = props;
  const titulo = 'EVOLUÇÃO SOLICITAÇÃO';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const itensNome = data.map((item) => {
    const str = item.data;
    const mes = str.substring(0, 3);
    const ano = str.substring(str.indexOf(' ') + 1).trim();
    return `${mes} - ${ano}`;
  });

  const itensQtde = data.map((item) => {
    return item.totalSolicitacao;
  });

  const itensQtdeAprovados = data.map((item) => {
    return item.totalSolicitacaoAprovada;
  });

  const itensQtdeArte = data.map((item) => {
    return item.totalArteAprovada;
  });

  const itensQtdeImpressoAprovado = data.map((item) => {
    return item.totalImpressoAprovado;
  });

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="tabela-container">
        <table className="tabela">
          <thead>
            <tr>
              <th
                style={{ width: '230px' }}
                aria-label="header"
                key="he"
                className="fixed-header fixed-col-1"
              />
              {itensNome.map((item, index) => {
                return (
                  <th style={{ width: `100px` }} key={`h${itensNome[index]}`}>
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td key="ts" className="fixed-col-1">
                Total Solicitações Criadas
              </td>
              {itensQtde.map((item, index) => {
                return (
                  <td key={`ts${itensNome[index]}`}>
                    {item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td key="ta" className="fixed-col-1">
                Total Solicitações Aprovadas
              </td>
              {itensQtdeAprovados.map((item, index) => {
                return (
                  <td key={`ta${itensNome[index]}`}>
                    {item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td key="aa" className="fixed-col-1">
                Arte Aprovada
              </td>
              {itensQtdeArte.map((item, index) => {
                return (
                  <td key={`aa${itensNome[index]}`}>
                    {item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td key="te" className="fixed-col-1">
                Total Impressos Aprovados
              </td>
              {itensQtdeImpressoAprovado.map((item, index) => {
                return (
                  <td key={`te${itensNome[index]}`}>
                    {item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EvolucaoSolicitacao;
